
<template>
  <div class="category-overview">
    <div class="row">
      <div class="col-md-4 order-md-1">
          <category-image />
      </div>
      <div class="col-md-8 mt-3 mt-md-0">
        <div class="row">
          <div class="col-12">
            <div class="form-group mb-3">
              <label for="category-name">
                Category Name
                <span class="text-danger">*</span>
              </label>
              <input type="text" v-validate="{ required: true}" v-model="data.name" id="category-name" 
              name="name" class="form-control" :class="{ 'is-invalid': errors.has('name') }" placeholder="e.g : 3D Logo Design" />
              <span class="text-danger invalid-feedback"  v-show="errors.has('name')">{{ errors.first('name') }}</span>
            </div>
          </div>
          <div class="col-12">
            <div class="form-group mb-3">
              <label for="slug">
                Slug
                <span class="text-danger">*</span>
              </label>
              <input type="text" v-model="data.slug" v-validate="{ required: true, regex: /^[a-z0-9-_]+$/}" 
              id="slug" class="form-control" name="slug" :class="{ 'is-invalid': errors.has('slug')}" placeholder="e.g : 3d-logo-design" />
              <span class="text-danger invalid-feedback" v-show="errors.has('slug')">{{ errors.first('slug') }}</span>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group mb-3">
              <b-form-checkbox class="mr-2" v-model="data.is_featured" name="is-featured" switch>
                Featured Category
              </b-form-checkbox>
              <b-form-checkbox v-model="data.is_active" name="is-active" switch>
                Active
              </b-form-checkbox>
            </div>
          </div>
           <div class="col-md-6">
            <div class="form-group mb-3">
              <label class="d-block" >Position</label>
              <b-form-spinbutton inline v-model="data.position" min="1" max="100"></b-form-spinbutton>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="">
      <div class="form-group text-right m-b-0">
        <b-button variant="primary" @click.prevent="updateCategory" type="button">Submit</b-button>
      </div>
    </div>
  </div>
</template>
<script>
import CategoryImage from "./CategoryImage.vue"

export default {
  components:{
      CategoryImage
  },
  data() {
    return {
      data: {},
    }
  },
  computed: {
    category(){
        return this.$store.state.categoryList.category
    }
  },
  methods: {
    updateCategory() {
      this.$validator.validateAll().then(result => {
        if(!result){ this.alertError("Form not valid"); return}
        const formData = new FormData()
        formData.append('data', JSON.stringify(this.data))
        this.$store.dispatch("changeLoaderValue", true)
        this.$http.post(`/categories/${this.category.id}/update`, formData)
        .then((response) => {
          this.$store.dispatch("changeLoaderValue", false)
          if(response.data.success){
            this.$store.commit("categoryList/UPDATE_CATEGORY", response.data.data)
          }
        })
      })
    },
    patchCategory(){
      this.data = {
        name: this.category.name,
        slug: this.category.slug,
        is_active: this.category.is_active,
        is_featured: this.category.is_featured,
        position: this.category.position,
      }
    },
  },
  created(){
    this.patchCategory()
  },
}
</script>
